<template>
<div style="overflow-x:hidden;margin: -15px;padding: -15px">
  <!-- 模板管理 -->
<div style=" margin: -15px -15px;overflow: hidden">
  <!-- 头部 -->
  <div class="top" >
    <div style="margin-top:20px;margin-left:35px;font-size:18px;color:rgba(185, 188, 191, 0.883)">类型:</div>
    <div style="margin-top:10px;margin-left:50px;font-size:18px">
    <a-radio-group default-value="-1" button-style="solid"   @change="onChange">
        <a-radio-button value="-1">
         全部
        </a-radio-button>
        <a-radio-button  :value="item.id" v-for="(item,index) in topData" :key="index">
        {{item.name }}
        </a-radio-button>
    </a-radio-group>
    </div>
  </div>

  <!-- 搜索框 -->
  <div class="search" >
    <div style="margin-left:400px" >
    <a-input placeholder="输入模板备注进行搜索" style="width:300px"  v-model="remark"/>
    <a-button type="primary" style="margin-left:10px;font-size: 16px;" @click="search">
    <a-icon type="search"  />
      搜索
    </a-button>
    <a-button @click="clear" style="margin-left:20px">重置</a-button>

    </div>
   </div>
  <!-- 模板 -->
  <div class="template" v-for="(item,index) in showData" :key="index">
    <!-- 图片 -->
   <div class="box">
    <div style="width:100%;height:70%">
        <img :src="item.previewImg"  style="width:100%;height:100%">
    </div>
    <!-- 姓名 -->
    <div class="created">
        <a>{{ item.author }}</a>
        <a>{{ item.createdTime}}</a>

    </div>
    <!-- 操作 -->
    <div class="content">
        <a @click="edit(item)">编辑</a><span>|</span>
        <a  :href="item.fileUrl" download="filename">下载</a><span>|</span>
        <a-popconfirm
                 title="确定删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="del(item.id)"
                 @cancel="cancel"> 
         <a>删除</a>
      </a-popconfirm>
    </div>
   </div>
  </div>

</div>
<!-- 分页 -->
<div class="page">
   <div >
        <a-pagination
        :current="current"
        :total="total"
        show-quick-jumper
        @change="onchange1"
        show-size-changer
        @showSizeChange="onShowSizeChange"
         />
    </div>
</div>
<!-------------------------------------弹窗 ------------------------------->
  <a-drawer
      title="编辑"
      placement="right"
      :closable="true"
      :visible="visible"
      width="500px"
      @close="onClose()"
    >
    <a-form-model  :model="entity"  ref="ruleForm_add">
      <a-form-model-item :label=label  style="display:flex" class="item" prop="">
       <div >
       <p>{{ this.filename }}</p>
   </div> 
    </a-form-model-item>
    <a-form-model-item label="预览图"  class="uploadImg">
      <div style="display:inline-block">
        </div>
        <div style="width:145px;height:125px;border:1px dashed rgb(203, 200, 200);border-radius:5px;position:absolute;left:30px;top: -10px;">
                <img :src="entity.previewImg" style="width:100%;height:100%;padding:4px 4px">
        </div>
    </a-form-model-item>
    <a-form-model-item label="备注" style="display:flex;position:absolute;left:30px;top: 310px;">
      <textarea placeholder="请输入备注内容"
        v-model="entity.remark"
        style="width:300px;height:100px;
        border:1px solid rgb(203, 200, 200);
        outline: none;">
      </textarea>
    </a-form-model-item>
    </a-form-model>

    <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
    >
      <a-button  @click="close">
        取消
      </a-button> &ensp;
     <a-button type="primary" @click="submit">
        确定
      </a-button>  
    </div>
  </a-drawer>
</div>

</template>

<script>
import {getList,gettemplate,getSearch,Delete,EditMoban,update} from  '../../../service/dictionary'

export default {
        name:'templatemanage',
        data(){
            return{
              ImgKey: '',
              fileList:[],
              topData:[],//头部类型
              showData:[], //模板展示
               total:0,
               current: 1,  //页数
               pageSize: 10,
               typeid:-1,//默认展示全部
               remark:'' ,//输入框输入内容
               entity:{
                id:'',
                previewImg:'',//预览图
                fileUrl:'',  //文件路径
                remark:'',
               },
               visible:false,
               filename:'', //文件名
               nameshow:true,
               label:''
            }
        },
        created() {
            // 获取头部数据
            getList(1).then((res)=>{
                 this.topData=res.data
            })
        },
        mounted(){
            this.load()
           },
        methods: {
             // 渲染模板
            load(){
                gettemplate({
                    curIndex:this.current,
                    pageSize:this.pageSize,
                    typeId:this.typeid,
                    }).then((res)=>{
                    this.showData=res.data
                    this.total=res.count
                    })
                },
            // 改变页码时调用
            onchange1(current){
                    this.current=current
                    this.load()
                },
                // 改变页数时调用
            onShowSizeChange(current,pageSize){
                    this.pageSize=pageSize
                    this.current=1
                    this.load()
                },
                // 头部数据改变时调用
            onChange(e){
                  this.typeid= e.target.value
                  this.load()
                },
                // 搜索
            search(){
              if(this.remark==''){
                 this.load()
                }
              else{
                getSearch({
                    remark:this.remark,
                    curPage:this.current,
                    pageSize:this.pageSize,
                    typeId:this.typeid
                }).then((res)=>{
                    this.showData=res.data
                    this.total=res.count
                })
              }
                },
              // 重置
            clear(){
                this.remark=''
                this.load()
              },
                // 删除
            async del(id){
                const response=await Delete(id)
                if(response.code===0){
                  this.$message.success("删除成功")
                  await this.load()
                }
                else{
                  this.$message.warning("删除失败")
                }
                },
            // 打开编辑窗口
            edit(item){
              this.entity.previewImg=item.previewImg
              this.entity.id=item.id
              this.entity.remark=item.remark
              this.entity.fileUrl=item.fileUrl
              this.label=item.designName +'源文件'
              this.visible=true
              let name = "";
              // 回显文件名
              if (this.entity.fileUrl !== null && this.entity.fileUrl!== "") {
                name = this.entity.fileUrl.substring(this.entity.fileUrl.lastIndexOf("/") + 1);
                this.filename=name
              } 
              else {
                name = "无";
              }
            },
            // 编辑提交按钮
            async submit(){
               let data={
                 id:this.entity.id,
                 remark:this.entity.remark,
               }
               const response=await EditMoban(data)
               if(response.code===0){
                await this.load()
                this.visible=false
                this.$message.success('编辑成功')
               }
               else{
                this.$message.warning('编辑失败')
               }
            },
          
          // 弹窗取消按钮
          close(){
            this.visible=false 
          },
          // 删除模板
          cancel(){
            this.$message.warning('取消删除');
          },
         onClose(){
            this.visible=false
         },
         },
        watch: {
          visible() {
            if (this.visible) {
              this.ImgKey = ''
            } else {
              this.ImgKey = Math.random()
            }
          },
   },
}

</script>

<style scoped lang="scss" >

.top{
  width: 100%;
  height: 100px;
  border-bottom: 15px solid #f0f2f5;
//   justify-content: space-between;
}
.search{
    width: 100%;
    height: 70px;
    border-bottom: 15px solid #f0f2f5;
    display: flex;
    justify-content:center;
    align-items:center
}
.box{
    width:22%;
    height: 270px;
    border:1px  solid #f0f2f5;
    // display:flex;
    float: left;
    margin-top:20px;
    margin-left: 25px;
    margin-bottom:20px;
    .created{
        width:100%;
        height: 12%;
        display: flex;
        font-size: 10px;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(to right,rgba(162, 160, 160, 0.5),rgba(213, 210, 210, 0.3));
        padding:0px 3px;
        a{
          color:#868686
        }
    };
    .content{
            display: flex;
            justify-content: space-around;
            color: #868686;
            font-size: 14px;
            height: 48px;
            line-height: 48px;
            background-color: rgb(253, 248, 248);
            padding:0px 7px;
            width: 100%;
            a{
               color: #787878;
            };
            span{
                color: rgb(239, 236, 236);
            }

    }
};
.ant-radio-button-wrapper {
  margin-right: 40px;
  border-radius: 5px;
  border:none;
  font-size: 17px;
  font-weight: 550;
  &::before {
    display: none;// 隐藏分界线
  }
};
 .ant-input{
    &::placeholder {
      text-align: center;
    }
  }
  .page{
    width:98%;
    display: flex;
    justify-content: right;
     //display: block;
     margin-top:70px;
     //margin-left:300px
  }

.ant-upload-text{
  font-size:16px;
}
::v-deep .ant-drawer-title{
  font-size:20px;
  font-weight: 600;
}
::v-deep .ant-form-item-label > label {
  font-size:16px
}
.uploadImg{
  display: flex;
  position: relative;
  }

</style>
